import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { service1, service2, service3, service4 } from '../../assets/images';

export default function ServicesSlider({onOpenModal}) {
    const settings = {
        loop: !0,
        margin: 30,
        nav: !1,
        dots: !0,
        mouseDrag: !0,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            768: {
                items: 2
            },
            1e3: {
                items: 3
            }
        }
    }
    return (
        <>
            <OwlCarousel className='owl-theme' {...settings}>
                <div class='item'>
                    <div class="service-box-wrap">
                        <img src={service1} alt="" class="img-fluid" />
                        <p className='h3'>Iconic Logo Design</p>
                        <div class="overlay-content">
                            <p className='h2'>Iconic Logo Design</p>
                            <p>Our iconic logos are sleek and memorable, designed to stand out with simplicity and impact.</p>
                            <a href="javascript:;" class="service-btn" onClick={onOpenModal}>ORDER NOW</a>
                        </div>
                    </div>
                </div>
                <div class='item'>
                    <div class="service-box-wrap">
                        <img src={service2} alt="" class="img-fluid" />
                        <p className='h3'>Mascot Logo Design</p>
                        <div class="overlay-content">
                            <p className='h2'>Mascot Logo Design</p>
                            <p>We bring your brand to life with mascot logos that add personality and engage your audience.</p>
                            <a href="javascript:;" class="service-btn" onClick={onOpenModal}>ORDER NOW</a>
                        </div>
                    </div>
                </div>
                <div class='item'>
                    <div class="service-box-wrap">
                        <img src={service3} alt="" class="img-fluid" />
                        <p className='h3'>Illustrative Logo Design</p>
                        <div class="overlay-content">
                            <p className='h2'>Illustrative Logo Design</p>
                            <p>Our illustrative logos tell your brand's story through detailed and creative artwork.</p>
                            <a href="javascript:;" class="service-btn" onClick={onOpenModal}>ORDER NOW</a>
                        </div>
                    </div>
                </div>
                <div class='item'>
                    <div class="service-box-wrap">
                        <img src={service4} alt="" class="img-fluid" />
                        <p className='h3'>Animated Logo Designs</p>
                        <div class="overlay-content">
                            <p className='h2'>Animated Logo Designs</p>
                            <p>Add movement and excitement to your brand with our eye-catching animated logos, perfect for digital spaces.</p>
                            <a href="javascript:;" class="service-btn" onClick={onOpenModal}>ORDER NOW</a>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </>
    )
}