import React, { useState } from 'react';
import axios from 'axios'; // Make sure axios is imported
import { loader } from '../../assets/images';

const BannerForm = () => {
  // State to track the current step (tab)
  const [step, setStep] = useState(0);

  // Form state
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    brief: '',
  });

  // State for loading and error handling
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate the current step before moving to the next
  const handleNextClick = () => {
    let canProceed = false;

    switch (step) {
      case 0:
        canProceed = formData.name.trim() !== "";
        break;
      case 1:
        canProceed = formData.email.trim() !== "";
        break;
      case 2:
        canProceed = formData.phone.trim() !== "";
        break;
      default:
        canProceed = true;
    }

    if (canProceed) {
      setStep((prevStep) => (prevStep < 3 ? prevStep + 1 : prevStep));
    } else {
      if (step === 0) {
        alert("Please fill in the required name field.");
      } else if (step === 1) {
        alert("Please fill in the required email field.");
      } else if (step === 2) {
        alert("Please fill in the required phone field.");
      }
    }
  };

  // Handle going back to the previous step
  const handlePrevClick = () => {
    setStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  // Handle form submission
  const onSubmitFunc = async (e) => {
    e.preventDefault();

    if (formData.phone.length < 9) {
      setErrors(true);
      return;
    } else {
      setErrors(false);
      const currentUrl = window.location.href;
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;

      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };

      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=468231';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status === 200) {
          const queryString = new URLSearchParams(response.data.data).toString();
          setLoading(false);
          setFormData({
            name: '',
            email: '',
            brief: '',
            phone: '',
          });

          window.location.href = `https://thecustomlogodesigns.com/thankyou?${queryString}`;
        }
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="banner-form-box">
      <div className="form_area">
        <form className="form-cta form_submission" id="regForm" onSubmit={onSubmitFunc}>
          <div className="row justify-content-left">
            <div className="col-lg-6 col-md-6 col-sm-6 form">
              {step === 0 && (
                <div className="form-group tab tab-1" style={{ display: 'block' }}>
                  <input
                    className="form-control"
                    name="name"
                    placeholder="Enter Your Name"
                    type="text"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              )}

              {step === 1 && (
                <div className="form-group tab tab-2" style={{ display: 'block' }}>
                  <input
                    className="form-control"
                    name="email"
                    placeholder="Enter Your Email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              )}

              {step === 2 && (
                <div className="form-group tab tab-3" style={{ display: 'block' }}>
                  <input
                    className="form-control"
                    name="phone"
                    placeholder="Enter Your Phone No"
                    type="number"
                    minLength="10"
                    maxLength="12"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              )}

              {step === 3 && (
                <div className="form-group tab tab-4" style={{ display: 'block' }}>
                  <input
                    className="form-control"
                    name="brief"
                    placeholder="Enter Brief"
                    type="text"
                    value={formData.brief}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              )}
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 p-lg-0">
              {/* Next and Previous buttons */}
              {step > 0 && (
                <button
                  className="btn header-btn prev btn default-btn btn-ct-sbt"
                  type="button"
                  onClick={handlePrevClick}
                >
                  Previous
                </button>
              )}
              {step < 3 && (
                <button
                  className="btn header-btn next btn default-btn btn-ct-sbt"
                  type="button"
                  onClick={handleNextClick}
                >
                  Next
                </button>
              )}

              {/* Submit button */}
              {step === 3 && (
                <button
                  className="btn header-btn submit btn default-btn btn-ct-sbt"
                  type="submit"
                  id="signupBtn"
                  disabled={loading}
                >
                  {loading ? 'Submitting...' : 'Submit'}
                </button>
              )}

              {loading && (
                <div className="loader">
                  <img alt="loader" src={loader} />
                </div>
              )}

              {errors && <p className="error-text">Phone number must be at least 9 digits long.</p>}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BannerForm;
