import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OwlCarousel from 'react-owl-carousel';
import { iconicLogoDesign01, iconicLogoDesign02, iconicLogoDesign03, iconicLogoDesign04, iconicLogoDesign05, iconicLogoDesign06, iconicLogoDesign07, iconicLogoDesign08, illustrativeLogo01, illustrativeLogo02, illustrativeLogo03, illustrativeLogo04, illustrativeLogo05, illustrativeLogo06, illustrativeLogo07, illustrativeLogo08, portfolio2d01, portfolio2d02, portfolio2d03, portfolio2d04, portfolio2d05, portfolio2d06, portfolio2d07, portfolio2d08, typographyLogo01, typographyLogo02, typographyLogo03, typographyLogo04, typographyLogo05, typographyLogo06, typographyLogo07, typographyLogo08 } from '../../assets/images';

function PortfolioTab() {
    const [key, setKey] = useState('iconic');
    const settings = {
        loop: !0,
        margin: 0,
        nav: !1,
        dots: !0,
        mouseDrag: !0,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            768: {
                items: 1
            },
            1e3: {
                items: 1
            }
        }
    }

    return (
        <div className='row portfolio-tab'>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="iconic" title="Iconic Logo">
                    <OwlCarousel className='owl-theme' {...settings}>
                        <div className='item'>
                            <div className='row justify-content-end'>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={iconicLogoDesign01} alt='' className='img-fluid'/>
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={iconicLogoDesign02} alt='' className='img-fluid'/>
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={iconicLogoDesign03} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={iconicLogoDesign04} alt='' className='img-fluid' />
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='row justify-content-end'>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={iconicLogoDesign05} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={iconicLogoDesign06} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={iconicLogoDesign07} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={iconicLogoDesign08} alt='' className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </Tab>
                <Tab eventKey="typography" title="Typography Logo">
                    <OwlCarousel className='owl-theme' {...settings}>
                        <div className='item'>
                            <div className='row justify-content-end'>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={typographyLogo01} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={typographyLogo02} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={typographyLogo03} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={typographyLogo04} alt='' className='img-fluid' />
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='row justify-content-end'>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={typographyLogo05} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={typographyLogo06} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={typographyLogo07} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={typographyLogo08} alt='' className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </Tab>
                <Tab eventKey="illustrative" title="Illustrative Logo">
                    <OwlCarousel className='owl-theme' {...settings}>
                        <div className='item'>
                            <div className='row justify-content-end'>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={illustrativeLogo01} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={illustrativeLogo02} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={illustrativeLogo03} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={illustrativeLogo04} alt='' className='img-fluid' />
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='row justify-content-end'>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={illustrativeLogo05} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={illustrativeLogo06} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={illustrativeLogo07} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={illustrativeLogo08} alt='' className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </Tab>
                <Tab eventKey="2d" title="2D Logo">
                    <OwlCarousel className='owl-theme' {...settings}>
                        <div className='item'>
                            <div className='row justify-content-end'>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={portfolio2d01} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={portfolio2d02} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={portfolio2d03} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={portfolio2d04} alt='' className='img-fluid' />
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='row justify-content-end'>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={portfolio2d05} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={portfolio2d06} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={portfolio2d07} alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <img src={portfolio2d08} alt='' className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </Tab>
            </Tabs>
        </div>

    );
}

export default PortfolioTab;