import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import {
    awards,
} from '../../assets/images';

export default function BigPackageSlider({ onOpenModal }) {

    const settings = {
        dots: true,
        margin: 0,
        nav: false,
        autoplay: !0,
        responsive: {
            0: {
                items: 1
            }
        },
    };
    return (
        <OwlCarousel class="pricingslider owl-carousel owl-theme" {...settings}>
            <div class="item mainpackagebox">
                <div class="bg-secondary">
                    <div class="row">
                        <div class="col-md-8">
                            <h2 class="title">Logo Infinite</h2>
                            <div class="combo-list-style">
                                <ul>
                                    <li>Unlimited Original Logo Concepts </li>
                                    <li>12  Dedicated Logo Designer (Industry Specific) </li>
                                    <li>Unlimited Revisions </li>
                                    <li>5 Page Informative Website </li>
                                    <li>Stationery Design (Business Card, Letterhead, Envelope) </li>
                                    <li>Brand Book</li>
                                    <li>500 Business Cards</li>
                                    <li>500 Letterheads</li>
                                    <li>Email Signature Design</li>
                                    <li>Social Media Designs (Facebook, Twitter, Instagram)</li>
                                    <li>2 Sided Flyer OR Bi-Fold Brochure Design</li>
                                    <li>With Grey Scale Format </li>
                                    <li>Free Icon Design </li>
                                    <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                    <li>24 - 48 Business Hours Turn Around Time</li>
                                    <li><span>MORE FEATURES</span></li>
                                    <li>100% Satisfaction </li>
                                    <li>100% Ownership Rights </li>
                                    <li>Money Back Guarantee </li>
                                    <li>Dedicated Account Manager </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 has-pad">
                            <div class="final">
                                <h3 class="ult-cut-prc"><span class="inner">$ 3330</span></h3>
                                <h1 class="price">$ 1249</h1>
                                <a href="javascript:;" class="popupBox btn-default seller_order" onClick={onOpenModal}>ORDER NOW</a>
                            </div>
                            <figure>
                                <img width="257" height="470" class="m-auto lazyload img-fluid" src={awards} alt="" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item mainpackagebox">
                <div class="bg-secondary">
                    <div class="row">
                        <div class="col-md-8">
                            <h2 class="title">Logo Combo</h2>
                            <div class="combo-list-style">
                                <ul>
                                    <li>Unlimited Original Logo Concepts </li>
                                    <li>12  Dedicated Logo Designer (Industry Specific) </li>
                                    <li>Unlimited Revisions </li>
                                    <li>7 Page Informative Website </li>
                                    <li>Stationery Design (Business Card, Letterhead, Envelope) </li>
                                    <li>Brand Book</li>
                                    <li>500 Business Cards</li>
                                    <li>500 Letterheads</li>
                                    <li>Email Signature Design</li>
                                    <li>Social Media Designs (Facebook, Twitter, Instagram)</li>
                                    <li>2 Sided Flyer OR Bi-Fold Brochure Design</li>
                                    <li>With Grey Scale Format </li>
                                    <li>Free Icon Design </li>
                                    <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                    <li>24 - 48 Business Hours Turn Around Time</li>
                                    <li><span>MORE FEATURES</span></li>
                                    <li>100% Satisfaction </li>
                                    <li>100% Ownership Rights </li>
                                    <li>Money Back Guarantee </li>
                                    <li>Dedicated Account Manager </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 has-pad">
                            <div class="final">
                                <h3 class="ult-cut-prc"><span class="inner">$ 3999</span></h3>
                                <h1 class="price">$ 1499</h1>
                                <a href="javascript:;" class="popupBox btn-default seller_order" onClick={onOpenModal}>ORDER NOW</a>
                            </div>
                            <figure>
                                <img width="257" height="470" class="m-auto lazyload img-fluid" src={awards} alt="" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </OwlCarousel>
    );
}


