import React, { useState, useRef } from 'react';
import './index.css'; // Ensure you have the right styles

const FlipsterCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(2); // Center image index
    const itemRefs = useRef([]);

    // Handle Next/Prev Navigation
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <div className="flipster-carousel">
            <ul className="flip-items">
                {images.map((image, index) => {
                    // Determine class for item
                    const className =
                        index === currentIndex
                            ? 'current'
                            : index < currentIndex
                                ? 'past'
                                : 'future';

                    // Determine if this item should trigger next or prev
                    const handleClick = () => {
                        if (className === 'past') {
                            handlePrev();
                        } else if (className === 'future' || className === 'current') {
                            handleNext();
                        }
                    };

                    return (
                        <li
                            key={index}
                            ref={(el) => (itemRefs.current[index] = el)}
                            className={`flip-item ${className}`}
                            style={{
                                transform: `translateX(${(index - currentIndex) * 100}px) scale(${index === currentIndex ? 1 : 0.7})`,
                            }}
                            onClick={handleClick} // Attach the appropriate click handler
                        >
                            <div>
                                <a href="javascript:;">
                                    <img src={image.src} alt={image.alt} className="flip-item__image" />
                                </a>
                            </div>

                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default FlipsterCarousel;
