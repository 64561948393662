import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { WEBSITE_NAME } from '../../config';
import { image1, image2, image3, image4 } from '../../assets/images';

export default function TestimonialSlider() {
    const settings = {
        items: 2,
        margin: 20,
        responsive: {
            0: {
                items: 1,
                dots: !0
            },
            600: {
                items: 1
            },
            768: {
                items: 1
            },
            992: {
                items: 2
            }
        },
        
    };
    
    return (
        <>
            <OwlCarousel className='owl-carousel owl-theme' {...settings}>
                <div className='item'>
                    <div className="client_area">
                        <div className="clin_img">
                            <img src={image1} alt="Client" />
                        </div>
                        <div className="client_content text-center">
                            <h4>KEVIN COUGHLIN</h4>
                            <h2>PROGRAM COORDINATOR</h2>
                            <p>
                            I had a rough idea of what I wanted, but the team took it beyond what I could imagine. They really listened to my vision and turned it into something that feels like an extension of my brand. The logo is bold, clear, and just what I needed. I’ve already gotten so much positive feedback from my clients
                            </p>
                        </div>
                    </div>
                </div>
                
                <div className='item'>
                    <div className="client_area">
                        <div className="clin_img">
                            <img src={image2} alt="Client" />
                        </div>
                        <div className="client_content text-center">
                            <h4>HUBERT JANOWSKI</h4>
                            <h2>DIGITAL MANAGER</h2>
                            <p>
                            They really took the time to understand my business and what I was looking for. The creativity and attention to detail were incredible throughout the whole process. The logo they delivered is fresh, unique, and has given my brand the standout look I was hoping for. It's made a huge difference
                            </p>
                        </div>
                    </div>
                </div>

                <div className='item'>
                    <div className="client_area">
                        <div className="clin_img">
                            <img src={image4} alt="Client" />
                        </div>
                        <div className="client_content text-center">
                            <h4>ANNE GUYOTTE</h4>
                            <h2>MARKETING MANAGER</h2>
                            <p>
                            What impressed me most was their commitment to getting every detail just right. They didn’t stop until the logo was perfect, and I couldn’t be happier with the result. It’s modern, eye-catching, and fits perfectly with my brand’s values. I’ll definitely be coming back for future design work
                            </p>
                        </div>
                    </div>
                </div>

                <div className='item'>
                    <div className="client_area">
                        <div className="clin_img">
                            <img src={image3} alt="Client" />
                        </div>
                        <div className="client_content text-center">
                            <h4>PROMOD SHARMA</h4>
                            <h2>ACTUARY</h2>
                            <p>
                            Working with them was such a smooth experience. They were incredibly patient and took my scattered ideas, turning them into something cohesive and beautiful. The logo perfectly represents my brand, and I’m proud to show it off.
                            </p>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </>
    );
}
