import React from 'react';
import FlipsterCarousel from '../FlipsterCarousel';

// Importing local images
import image1 from '../../assets/images/banner-logo/01.webp';
import image2 from '../../assets/images/banner-logo/02.webp';
import image3 from '../../assets/images/banner-logo/03.webp';
import image4 from '../../assets/images/banner-logo/04.webp';
import image5 from '../../assets/images/banner-logo/05.webp';

const images = [
  { src: image1, alt: 'Image 1' },
  { src: image2, alt: 'Image 2' },
  { src: image3, alt: 'Image 3' },
  { src: image4, alt: 'Image 4' },
  { src: image5, alt: 'Image 5' },
];

const FlipsterComponent = () => {
  return (
    <div>
      <FlipsterCarousel images={images} />
    </div>
  );
};

export default FlipsterComponent;
