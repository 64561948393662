import TestimonialSlider from '../TestimonialSlider';
import './index.css';

function Testimonials() {
    return (
        <section className='testimonials'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-5'>
                        <p className='h3'>
                            Hear <br />
                            <span className='orange'>What Our Clients</span><br />
                            Are Saying
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <p>
                            Don’t just take our word for it—our clients love the logos we’ve created for them. Here’s what some of them had to say about their experience working with us:
                        </p>
                    </div>
                </div>
                <TestimonialSlider />
            </div>
        </section>
    );
}

export default Testimonials;
