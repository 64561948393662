import { footerCardIcon } from '../../assets/images';
import './index.css';
import { WEBSITE_NAME } from '../../config';

function Footer() {
    return (
        <section className='footer bg-dark text-white'>
            <div className='container'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-lg-4 col-md-6 terms-privacy'>
                        <a href='https://www.acedesignstudios.com/terms'>
                            Terms & Conditions
                        </a>
                        <a href='https://www.acedesignstudios.com/privacy'>
                            Privacy Policy
                        </a>
                    </div>
                    <div className='col-lg-4 col-md-6 copyright'>
                        <p className='mb-0'>
                        Copyright © 2024 {WEBSITE_NAME} | All rights reserved.
                        </p>
                    </div>
                    <div className='col-md-4 img-div'>
                        <img src={footerCardIcon} alt='' className='img-fluid'/>
                    </div>
                </div>
                <p className='orange text-center disclaimer-head pt-2'>Disclaimer:</p>
                <p className='mb-0 disclaimer text-center'>
                The logo, name and graphics of {WEBSITE_NAME} and its products & services are the trademarks of {WEBSITE_NAME}. All other company names, brand names, trademarks and logos mentioned on this website are the property of their respective owners and do not constitute or imply endorsement, sponsorship or recommendation thereof by{WEBSITE_NAME}
                </p>
            </div>
        </section>
    );
}

export default Footer;
