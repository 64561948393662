import BigPackageSlider from '../BigPackageSlider';
import PricingSlider from '../PricingSlider';
import './index.css';

function Pricing({ onOpenModal }) {
    return (
        <section className='pricing'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-5'>
                        <p>
                            Tailored<br/><span className='orange'>Payment Plans</span><br/>  That Works for You
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <p>
                        We design logos that stand out and make a lasting impression in a competitive market. Pick the payment plan that fits your needs, and let us bring your brand to life with designs that drive recognition.
                        </p>
                    </div>
                </div>
                <PricingSlider onOpenModal={onOpenModal}/>
                <div class="top-packages">
                    <BigPackageSlider onOpenModal={onOpenModal}/>
                </div>
            </div>
        </section>
    );
}

export default Pricing;
