import ServicesSlider from '../ServicesSlider';
import './index.css';
import { WEBSITE_NAME } from '../../config';

function Services({onOpenModal}) {
    return (
        <section className='services'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-5'>
                        <p>
                            <span className='orange'>Designing Logos</span><br /> That Speak Your Brand’s Language
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <p>
                            Our talented designers bring years of expertise in creating logos that not only grab attention but also align perfectly with your brand’s values and vision. Whether you're looking for something sleek and minimalistic or bold and vibrant, we've got the perfect design solution for you.
                        </p>
                    </div>
                </div>
                <ServicesSlider onOpenModal={onOpenModal} />
            </div>
        </section>
    );
}

export default Services;
