const Admin_Panel = 'info@alphadesigncrew.com';
const PHONE = '(855) 535-9320';
const PHONE_HREF = 'tel:8555359320';
const EMAIL = 'info@alphadesigncrew.com';
const EMAIL_HREF = 'mailto:info@alphadesigncrew.com';
const CONTACT_EMAIL = 'info@alphadesigncrew.com';
const CONTACT_EMAIL_HREF = 'mailto:info@alphadesigncrew.com';
const ADDRESS = '220 S Almaden Ave, San Jose, CA 95113, USA';
const WEBSITE_NAME = 'Custom Logo Designs';
const SITE_CURRENCY_SYMBOLS = '$';

export {
  Admin_Panel,
  PHONE,
  PHONE_HREF,
  EMAIL,
  EMAIL_HREF,
  CONTACT_EMAIL,
  CONTACT_EMAIL_HREF,
  ADDRESS,
  WEBSITE_NAME,
  SITE_CURRENCY_SYMBOLS,
};
