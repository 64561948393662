import { clientLogo } from '../../assets/images';
import './index.css';

function ClientLogo() {
    return (
        <section className='client-logo'>
            <div className='container'>
                <img src={clientLogo} alt='' className='img-fluid'/>
            </div>
        </section>
    );
}

export default ClientLogo;
