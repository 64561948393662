import PortfolioTab from '../PortfolioTab';
import './index.css';
import { WEBSITE_NAME } from '../../config';

function Portfolio() {
    return (
        <section className='portfolio'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-5'>
                        <p className='h3'>
                            A Showcase of<br /><span className='orange'>Creativity and Precision</span>
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <p>
                            Our portfolio highlights diverse logos created with one goal in mind, bringing brands to life. From bold to subtle, every design tells a story tailored to each business.
                        </p>
                    </div>
                </div>
                <PortfolioTab />
            </div>
        </section>
    );
}

export default Portfolio;
