import { PHONE, PHONE_HREF } from '../../config';
import './index.css';

function AwardCTA({ onOpenModal }) {
    return (
        <section className='award-cta'>
            <div className='container'>
                <div className='col-md-12'>
                    <p className='h1'>
                        Your Brand Deserves More<br />
                        <span className='orange'>Let’s Create a Bold New logo for Your Business</span>
                    </p>
                    <p>
                    Step into the spotlight with a logo that grabs attention and leaves a memorable mark. Let’s start designing your Premium Logo designs
                    </p>
                    <div class="cta-button">
                        <a href="javascript:;" class="cta-btn bg-white text-black chat">Lets Talk</a>
                        <a href="javascript:;" class="cta-btn" onClick={onOpenModal}>START PROJECT
                            NOW
                            <i class="fas fa-arrow-circle-right"></i></a>
                        <a href={PHONE_HREF} class="cta-btn white-bg"><i
                            class="fas fa-phone"></i>{PHONE}</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AwardCTA;
