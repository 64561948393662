import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import './index.css';
import { loader } from '../../assets/images';

export const ModalSection = ({ open, onCloseModal }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
    });

    // Handle input changes to update form data
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const onSubmitFunc = async (e) => {
        e.preventDefault();

        console.log('formData', formData);

        // Phone number validation (at least 9 characters)
        if (formData.phone.length < 9) {
            setErrors(true);
        } else {
            setErrors(false); // Reset errors

            const currentUrl = window.location.href;
            const protocol = window.location.protocol; // "https:"
            const hostname = window.location.hostname; // "creativeghostwriters.org"

            // Construct the base URL
            const baseUrl = `${protocol}//${hostname}`;
            const queryStringFormData = new URLSearchParams(formData).toString();

            let finalReq = {
                ...formData,
                source: `${currentUrl}${queryStringFormData}`,
                domain: baseUrl,
                lead_url: currentUrl,
                url: `${currentUrl}${queryStringFormData}`,
            };

            try {
                setLoading(true); // Start loading
                const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=468231';
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                };

                const formDataString = new URLSearchParams(finalReq).toString();

                const response = await axios.post(apiUrl, formDataString, config);

                if (response.status === 200) {
                    const queryString = new URLSearchParams(response.data.data).toString();
                    console.log('queryString', queryString);
                    setLoading(false); // Stop loading
                    setFormData({ name: '', email: '', phone: '' }); // Reset form
                    window.location.href = `https://thecustomlogodesigns.com/thankyou?${queryString}`;
                }
            } catch (error) {
                console.error('Error:', error);
                setLoading(false); // Stop loading
            }
        }
    };

    return (
        <Modal
            id="popup_form"
            show={open}
            onHide={onCloseModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="entry-box">
                    <form className="offr-frm form_submission" onSubmit={onSubmitFunc}>
                        <div className="col-sm-12 entry-left">
                            <button type="button" className="close" onClick={onCloseModal}>×</button>
                            <h1>up to 70% Discount</h1>
                        </div>
                        <div className="col-sm-12 entry-right">
                            <div className="text">
                                <h2><span>70% off </span> till midnight</h2>
                                <p>Fill out this brief form to get your <strong>Discount reserved.</strong></p>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        placeholder="Your Name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        placeholder="Email Address"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="phone"
                                        minLength="10"
                                        maxLength="12"
                                        placeholder="Phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors && <p className="error-text">Phone number must be at least 9 digits.</p>}
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group mb-0 mt-4">
                                    <button
                                        type="submit"
                                        className="entery-submit"
                                        name="signupForm"
                                        id="signupBtn"
                                        value="1"
                                        disabled={loading} // Disable button when loading
                                    >
                                        {loading ? 'Placing your order...' : 'Place your order'}
                                    </button>
                                    {loading && (
                                        <div className="loader">
                                            <img alt="loader" src={loader} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};
