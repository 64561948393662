import { logo } from '../../assets/images';
import { PHONE, PHONE_HREF } from '../../config';
import BannerForm from '../BannerForm';
import FlipsterComponent from '../FlipsterComponent';
import './index.css';

function Header({ onOpenModal }) {
    return (
        <section className='banner'>
            <header className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-6 logo-col'>
                        <img src={logo} alt='logo' className='img-fluid'/>
                    </div>
                    <div className='col-md-9 cta-col'>
                        <div className='chat'>
                            <a href='javascript:;'><i class="fa-regular fa-comments"></i>&nbsp;Live Chat |</a>
                        </div>
                        <div className='phone'>
                            <a href={PHONE_HREF}><i class="fa-solid fa-phone-volume"></i>&nbsp;{PHONE} |</a>
                        </div>
                        <div className='headerbtn'>
                            <button className='btn' onClick={onOpenModal}>DESIGN MY LOGO</button>
                        </div>
                    </div>
                    <div className=' col-lg-9 col-md-6 col-sm-6 pr-lg-0 col-6 mob-cta-col'>
                        <div className='chat'>
                            <p><i class="fa-regular fa-comments"></i></p>
                        </div>
                        <div className='phone'>
                            <p><i class="fa-solid fa-phone-volume"></i></p>
                        </div>
                    </div>
                </div>
            </header>
            <div className='container banner-container'>
                <div className='row'>
                    <div className='col-md-12 col-lg-6 banner-text'>
                        <p className='h5'>
                            Your Brand <br/> Deserves a Unique Identity
                        </p>
                        <p className='h1'>
                        Premium <span className='orange'>Custom Logo<br/> Designs</span> that truly reflects your brand’s personality.
                        </p>
                        <BannerForm />
                        <a href="javascript:;" class="chat bt-red">
                            Chat With Us to get 70% Discount
                        </a>
                    </div>
                    <div className='col-md-6 banner-logos'>
                        <FlipsterComponent />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Header;
