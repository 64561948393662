import React from 'react';
import OwlCarousel from 'react-owl-carousel';

export default function PricingSlider({ onOpenModal }) {
    const settings = {
        loop: !1,
        margin: 0,
        nav: !0,
        navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],
        dots: !1,
        mouseDrag: !0,
        responsive: {
            0: {
                items: 1,
                dots: !0,
                nav: false,
            },
            600: {
                items: 1,
                nav: false,
            },
            768: {
                items: 2,
                nav: false,
            },
            1e3: {
                items: 3
            }
        },
    }
    return (
        <>
            <OwlCarousel className='owl-theme' {...settings}>
                <div className='item'>
                    <div class="package">
                        <p class="package-head">Logo Basic</p>
                        <div class="package-body">
                            <ul>
                                <li>4 Original Logo Concepts</li>
                                <li> 2 Dedicated Logo Designer</li>
                                <li> 4 Revisions</li>
                                <li> With Grey Scale Format</li>
                                <li> Free Icon Design</li>
                                <li> Formats: JPEG Only</li>
                                <li> 24 - 48 Hours Turn Around Time</li>
                                <li class="features">MORE FEATURES</li>
                                <li> 100% Satisfaction</li>
                                <li> 100% Ownership Rights</li>
                                <li> Money Back Guarantee</li>
                                <li> Dedicated Account Manager</li>
                            </ul>
                        </div>
                        <div class="package-footer">
                            <h1><span class="price packagePrice"><sup class="dsign">$</sup>35.00</span><sub>
                                <del>117.00</del>
                            </sub></h1>
                            <a href="javascript:void(0)" class="btn btn-order popupBox" onClick={onOpenModal}>Order Now</a>
                        </div>
                    </div>
                </div>
                <div className='item'>
                    <div class="package">
                        <p class="package-head">Logo Professional</p>
                        <div class="package-body">
                            <ul>
                                <li>12 Original Logo Concepts</li>
                                <li>4 Dedicated Logo Designer (Industry Specific)</li>
                                <li>Unlimited Revisions</li>
                                <li>Stationery Design (Business Card, Letterhead, Envelope)</li>
                                <li>Email Signature Design</li>
                                <li>With Grey Scale Format</li>
                                <li>Free Icon Design</li>
                                <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                <li>24 - 48 Hours Turn Around Time</li>
                                <li class="features">MORE FEATURES</li>
                                <li>100% Satisfaction</li>
                                <li>100% Ownership Rights</li>
                                <li>Money Back Guarantee</li>
                                <li>Dedicated Account Manager</li>
                            </ul>
                        </div>
                        <div class="package-footer">
                            <h1><span class="price packagePrice"><sup class="dsign">$</sup>119.00</span><sub>
                                <del>307.00</del>
                            </sub></h1>
                            <a href="javascript:void(0)" class="btn btn-order popupBox" onClick={onOpenModal}>Order Now</a>
                        </div>
                    </div>
                </div>
                <div className='item'>
                    <div class="package">
                        <p class="package-head">Logo Elite</p>
                        <div class="package-body">
                            <ul>
                                <li>Unlimited Original Logo Concepts</li>
                                <li>8 Dedicated Logo Designer (Industry Specific)</li>
                                <li>Unlimited Revisions</li>
                                <li>Stationery Design (Business Card, Letterhead, Envelope)</li>
                                <li>500 Business Cards </li>
                                <li>Email Signature Design</li>
                                <li>With Grey Scale Format</li>
                                <li>Free Icon Design</li>
                                <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                <li>24 - 48 Hours Turn Around Time</li>
                                <li class="features">MORE FEATURES</li>
                                <li>100% Satisfaction</li>
                                <li>Money Back Guarantee</li>
                                <li>Dedicated Account Manager</li>
                            </ul>
                        </div>
                        <div class="package-footer">
                            <h1><span class="price packagePrice"><sup class="dsign">$</sup>299.00</span><sub>
                                <del>849.00</del>
                            </sub></h1>
                            <a href="javascript:void(0)" class="btn btn-order popupBox" onClick={onOpenModal}>Order Now</a>
                        </div>
                    </div>
                </div>
                <div className='item'>
                    <div class="package">
                        <p class="package-head">The Boss</p>
                        <div class="package-body">
                            <ul>
                                <li>3D/Mascot/Animated Logo</li>
                                <li>Unlimited Original Logo Concepts</li>
                                <li>8 Dedicated Logo Designer (Industry Specific)</li>
                                <li> Unlimited Revisions</li>
                                <li>Stationery Design (Business Card, Letterhead, Envelope)</li>
                                <li>500 Business Cards</li>
                                <li>Email Signature Design</li>
                                <li>With Grey Scale Format</li>
                                <li>Free Icon Design</li>
                                <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                <li>24 - 48 Hours Turn Around Time</li>
                                <li class="features">MORE FEATURES</li>
                                <li>100% Satisfaction</li>
                                <li>Money Back Guarantee</li>
                                <li>Dedicated Account Manager</li>
                            </ul>
                        </div>
                        <div class="package-footer">
                            <h1><span class="price packagePrice"><sup class="dsign">$</sup>599.00</span><sub>
                                <del>1996.00</del>
                            </sub></h1>
                            <a href="javascript:void(0)" class="btn btn-order popupBox" onClick={onOpenModal}>Order Now</a>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </>
    )
}