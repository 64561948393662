import { ctaImg } from '../../assets/images';
import { WEBSITE_NAME, PHONE, PHONE_HREF } from '../../config';
import './index.css';

function MagicCTA({ onOpenModal }) {
    return (
        <section className='magic-cta'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-md-5'>
                        <img src={ctaImg} alt='' />
                    </div>
                    <div className='col-lg-5 col-md-12 magic-cta-text'>
                        <p className='h3'>
                            Get a <span>Custom Logo</span> That Tells Your Story
                        </p>
                        <p>
                            We specialize in turning ideas into powerful brands. Let’s connect and create a logo that’s more than just a design, it’s the heart of your brand.
                        </p>
                        <div class="cta-button">
                            <a href={PHONE_HREF} class="cta-btn bg-white text-black"><i class="fas fa-phone"></i>
                                {PHONE}</a>
                            <a href="javascript:;" class="cta-btn" onClick={onOpenModal}>START
                                PROJECT NOW <i class="fas fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MagicCTA;
