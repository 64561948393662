import React, { useState, useEffect } from 'react';
import ClientLogo from './Components/ClientLogo';
import Header from './Components/Header';
import './App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Services from './Components/Services';
import Pricing from './Components/Pricing';
import MagicCTA from './Components/MagicCTA';
import Portfolio from './Components/Portfolio';
import AwardCTA from './Components/AwardCTA';
import Testimonials from './Components/Testimonials';
import Footer from './Components/Footer';
import { ModalSection } from './Components/ModalSection';

// Crawler detection function
const isCrawler = () => {
  const userAgent = navigator.userAgent;
  console.log(userAgent);
  const crawlers = [
    'HeadlessChrome/',
    'Googlebot', 'Bingbot', 'Slurp', 'DuckDuckBot', 'Baiduspider',
    'YandexBot', 'Sogou', 'Exabot', 'facebot', 'ia_archiver', 'GTmetrix',
    'Chrome-Lighthouse',
  ];
  return crawlers.some(crawler => userAgent.includes(crawler));
};

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Initialize chat widget if not a crawler
  useEffect(() => {
    if (!isCrawler()) {
      console.log('true');
      // Initialize chat and open it by default
      const openChatWindow = () => {
        if (window.$zopim) {
          window.$zopim.livechat.window.show();
        }
      };

      const toggleChat = () => {
        if (window.$zopim) {
          window.$zopim.livechat.window.toggle();
        }
      };

      const initializeChat = () => {
        const intervalId = setInterval(() => {
          if (window.$zopim && window.$zopim.livechat) {
            clearInterval(intervalId);
            openChatWindow();
            listenForNewMessages();
          }
        }, 100); // Check every 100ms if chat widget is available
      };

      const listenForNewMessages = () => {
        if (window.$zopim && window.$zopim.livechat) {
          window.$zopim.livechat.setOnUnreadMsgs((numberOfUnreadMessages) => {
            if (numberOfUnreadMessages > 0) {
              openChatWindow();
            }
          });
        }
      };

      initializeChat();

      // Set up toggle chat buttons
      const chatButtons = document.querySelectorAll('.chat');

      chatButtons.forEach(button => {
        button.addEventListener('click', toggleChat);
      });

      return () => {
        // Clean up event listeners
        chatButtons.forEach(button => {
          button.removeEventListener('click', toggleChat);
        });
      };
    }
  }, []);

  return (
    <div className="App">
      {/* Components where modal can be opened */}
      <Header onOpenModal={handleOpenModal} />
      <ClientLogo />
      <Services onOpenModal={handleOpenModal} />
      <Pricing onOpenModal={handleOpenModal} />
      <MagicCTA onOpenModal={handleOpenModal} />
      <Portfolio />
      <AwardCTA onOpenModal={handleOpenModal} />
      <Testimonials />
      <Footer />

      {/* Modal Section */}
      <ModalSection open={isModalOpen} onCloseModal={handleCloseModal} />
    </div>
  );
}

export default App;
