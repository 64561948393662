export const image1 = require('./1.webp');
export const image10 = require('./10.webp');
export const image11 = require('./11.webp');
export const image12 = require('./12.webp');
export const image13 = require('./13.webp');
export const image14 = require('./14.webp');
export const image2 = require('./2.webp');
export const image3 = require('./3.webp');
export const image4 = require('./4.webp');
export const image5 = require('./5.webp');
export const image6 = require('./6.webp');
export const image7 = require('./7.webp');
export const image8 = require('./8.webp');
export const image9 = require('./9.webp');
export const ajaxLoader = require('./ajax-loader.gif');
export const awards = require('./awards.webp');
export const clientLogo = require('./client-logo.webp');
export const client2 = require('./client2.webp');
export const ctaBanner = require('./cta-banner.webp');
export const ctaImg = require('./cta-img.webp');
export const favicon = require('./favicon.webp');
export const footerCardIcon = require('./footer-card-icon.webp');
export const limiteds = require('./limiteds.webp');
export const logoBlack = require('./logo-black.png');
export const logo = require('./logo.png');
export const mainAward = require('./main-award.webp');
export const mainBannerRes = require('./main-banner-res.webp');
export const priceBoxBg = require('./price-box-bg.webp');
export const service1 = require('./service-1.webp');
export const service2 = require('./service-2.webp');
export const service3 = require('./service-3.webp');
export const service4 = require('./service-4.webp');
export const tele = require('./tele.webp');
export const testiBg = require('./testi-bg.webp');
export const loader = require('./loader.gif');


// Banner Logo Exports
export const bannerlogo01 = require('./banner-logo/01.webp');
export const bannerlogo02 = require('./banner-logo/02.webp');
export const bannerlogo03 = require('./banner-logo/03.webp');
export const bannerlogo04 = require('./banner-logo/04.webp');
export const bannerlogo05 = require('./banner-logo/05.webp');

// Portfolio 2D Logo Exports
export const portfolio2d01 = require('./portfolio/2d-logo/01.webp');
export const portfolio2d02 = require('./portfolio/2d-logo/02.webp');
export const portfolio2d03 = require('./portfolio/2d-logo/03.webp');
export const portfolio2d04 = require('./portfolio/2d-logo/04.webp');
export const portfolio2d05 = require('./portfolio/2d-logo/05.webp');
export const portfolio2d06 = require('./portfolio/2d-logo/06.webp');
export const portfolio2d07 = require('./portfolio/2d-logo/07.webp');
export const portfolio2d08 = require('./portfolio/2d-logo/08.webp');

// Iconic Logo Design Exports
export const iconicLogoDesign01 = require('./portfolio/iconic-logo-design/01.webp');
export const iconicLogoDesign02 = require('./portfolio/iconic-logo-design/02.webp');
export const iconicLogoDesign03 = require('./portfolio/iconic-logo-design/03.webp');
export const iconicLogoDesign04 = require('./portfolio/iconic-logo-design/04.webp');
export const iconicLogoDesign05 = require('./portfolio/iconic-logo-design/05.webp');
export const iconicLogoDesign06 = require('./portfolio/iconic-logo-design/06.webp');
export const iconicLogoDesign07 = require('./portfolio/iconic-logo-design/07.webp');
export const iconicLogoDesign08 = require('./portfolio/iconic-logo-design/08.webp');

// Illustrative Logo Exports
export const illustrativeLogo01 = require('./portfolio/illustrative-logo/01.webp');
export const illustrativeLogo02 = require('./portfolio/illustrative-logo/02.webp');
export const illustrativeLogo03 = require('./portfolio/illustrative-logo/03.webp');
export const illustrativeLogo04 = require('./portfolio/illustrative-logo/04.webp');
export const illustrativeLogo05 = require('./portfolio/illustrative-logo/05.webp');
export const illustrativeLogo06 = require('./portfolio/illustrative-logo/06.webp');
export const illustrativeLogo07 = require('./portfolio/illustrative-logo/07.webp');
export const illustrativeLogo08 = require('./portfolio/illustrative-logo/08.webp');

// Typography Logo Exports
export const typographyLogo01 = require('./portfolio/typography-logo/01.webp');
export const typographyLogo02 = require('./portfolio/typography-logo/02.webp');
export const typographyLogo03 = require('./portfolio/typography-logo/03.webp');
export const typographyLogo04 = require('./portfolio/typography-logo/04.webp');
export const typographyLogo05 = require('./portfolio/typography-logo/05.webp');
export const typographyLogo06 = require('./portfolio/typography-logo/06.webp');
export const typographyLogo07 = require('./portfolio/typography-logo/07.webp');
export const typographyLogo08 = require('./portfolio/typography-logo/08.webp');